import { Typography } from '@mui/material'

// Types
import type { TypographiesProps } from '@/types/components/atoms/typographies/main-header'

const QouteAtom = (props: TypographiesProps) => {
	// Props
	const { text, subtitle, sx } = props
	return (
		<>
			<Typography
				fontFamily="IM FELL Great Primer"
				fontSize={{ xs: 26, md: 40 }}
				fontWeight={400}
				lineHeight="113%"
				color="primary.main"
				fontStyle="italic"
				textAlign="center"
				sx={{ ...sx }}
			>
				{text}
			</Typography>
			<Typography fontSize={{ xs: 14, md: 24 }} textAlign="center" mt={2}>
				{subtitle}
			</Typography>
		</>
	)
}

export default QouteAtom
